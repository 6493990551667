.BarChart {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .bar-column {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        overflow: hidden;
    }

    .bar {
        border-radius: 50vw 50vw 0 0;
        overflow: hidden;
    }
}