.app-footer {
    display: flex;
    height: var(--footer-height);
    min-height: var(--footer-height);
    align-items: center;
    justify-content: space-between;
    padding-inline: 32px;

    flex-shrink: 0;
}

.app-footer {
    .logo {
        height: 24px;
    }

    .text, .text-wade-report-mode {
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
    }

    .text-wade-report-mode {
        color: #b7b7b7;
    }
}