.PointAnnotationAnimation {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 0, 0, 0.2);
}

.PointAnnotationAnimation .container {
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0px;
}

.PointAnnotationAnimation .circle, .PointAnnotationAnimation .circle2 {
    border: 2px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
}

.PointAnnotationAnimation .circle2 {
    border: 2px solid red;
}

.PointAnnotationAnimation .point {
    width: 12px;
    height: 12px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    transform: translate(-50%, -50%);
}
