.SecondHeader {
	.units-count-text {
	    font-family: Montserrat;
	    font-style: normal;
	    font-weight: var(--font-weight-regular);
	    font-size: 14px;
	    display: flex;
	    gap: 6px;
	}

	.units-count-label {
	    color: var(--gray-300);
	}

	.units-count-number {
	    color: white;
	}

	.wrapper {
	    height: 60px;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
	    padding-left: 32px;
	    padding-right: 32px;
	    gap: 16px;
	}

	.right {
	    display: flex;
	    gap: 16px;
	}

	.breadcrumb {
	    display: flex;
	    gap: 4px;
	    color: var(--gray-400) !important;
	    font-family: Montserrat;
	    font-style: normal;
	    font-size: 14px;
	}

	.path {
	    display: flex;
	    justify-content: flex-start;
	    align-items: center;
	    background: transparent;
	    gap: 10px;
	}

	.tiny-icon {
	    color: var(--gray-400);
	}

	.location-name {}

	.active-unit-address {
	    color: white;
	}

	.location-dropdown-wrapper {
	    display: flex;
	    place-items: center;
	    place-content: center;
	    width: 26px;
	    height: 26px;
	    cursor: pointer;
	    position: relative;
	}

	.location-dropdown-toggle {
	    width: 100%;
	    height: 100%;
	    align-content: center;
	    text-align: center;
	}

	.location-dropdown-toggle-on {
	    transform: rotate(180deg);
	}

	.location-dropdown-hide {
	    display: none !important;
	}

	.location-dropdown {
	    position: absolute;
	    top: 28px;
	    display: flex;
	    font-family: Montserrat;
	    border-radius: 8px;
	    overflow: hidden;
	    background-color: var(--gray-900);
	    border: 1px solid var(--gray-700);
	    flex-direction: column;
	    z-index: 1;
	    width: max-content;
	}

	.location-dropdown-elem {
	    padding: 8px;
	    text-align: start;
	    align-items: center;
	    cursor: pointer;
	    user-select: none;
	}

	.location-dropdown-elem:hover {
	    background-color: var(--gray-800);
	    color: white;
	}
}