.RecentPage {
    .wrapper {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
        padding: 100px;
        gap: 80px;
    }

    .img-wrapper {
        border-radius: 8px;
        margin: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .poster-image {
        display: block;
        width: 315px;
        height: 197px;
        object-fit: contain;
        background-color: black;
        position: relative;
        text-indent: -9999px;
        border-radius: 10px;
    }

    .poster-image::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../../../assets/icons/image_icon.png') no-repeat;
        background-size: 100px 100px;
        background-position: center;
        z-index: -9999;
        filter: grayscale(100%) invert(100%);
    }

    .location-bar {
        display: flex;
        padding-top: 16px;
        border-top: 2px solid var(--gray-800);
        flex-direction: row;
        justify-content: space-between;
    }

    .date-line {
        font-family: Montserrat;
        font-style: normal;
        font-weight: var(--font-weight-regular);
        font-size: 14px;
        display: flex;
        gap: 6px;
        color: var(--gray-300);
        margin-bottom: 12px;
    }

    .units-count-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: var(--font-weight-regular);
        font-size: 14px;
        display: flex;
        gap: 6px;
    }

    .units-count-label {
        color: var(--gray-100);
    }

    .units-count-number {
        color: white;
    }

    .location-bar-left {
        display: default;
    }

    .location-bar-right {
        display: flex;
        align-items: flex-end;
        gap: 16px;
    }

    .live-activity-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
    }

    .live-activity-dot {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background: var(--gray-500);
        border: 1px solid var(--gray-200);
    }
}
