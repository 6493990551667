.WadeReportWeekPage {
    /* Bar chart */

    .barchart-wrapper {
        margin: 0px 32px 0px 0px;
        width: 50%;
        display: flex;
        justify-content: center;
    }
    .barchart-card {
        width: 100%;
    }

    /* ------------------- */

    .date-line {
        font-weight: 300;
        font-family: Montserrat;
        font-size: 13px;
        color: var(--gray-300);
        margin: 0px 0px 4px 32px;
    }

	.wrapper {
        display: flex;
        justify-content: center;
        padding: 32px
    }
    
    .grid-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        margin: 0 0px;
    }

    .activations-card {
        width: 100%;
    }

    .trend_icon {
        margin-right: 8px;
    }

    .trend-label-increasing {
        color: var(--success-300);
        margin-right: 5px;
    }

    .trend-label-decreasing {
        color: var(--error-400);
        margin-right: 5px;
    }
    
    .donut-card {
        width: 100%;
    }

    .donut-card-wade-report {
        width: 50%;
    }

    .donut-wrapper {
        overflow: hidden;
        display: flex;
        flex: 1;
        flex-direction: row;
        width: 100%;
    }   

    .donut-wrapper-wade-report {
        overflow: hidden;
        display: flex;
        flex: 1;
        flex-direction: row;
        width: 100%;
    }
    
    .activity-card {
        width: 100%;
        font-family: Montserrat;
        color: var(--gray-300);
        font-size: 14px;
    }
    
    .bar-card {
        width: 100%;
    }

    .wade-report-mode-month {
        width: 100%;
    }
    
    .top-bar {
        margin-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    
    .left-bar {
        margin-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    
    .bottom-bar {
        display: flex;
        border-top: 2px solid var(--gray-800);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        overflow-y: auto;
        height: 100%;
    }
    
    .right-bar {
        display: flex;
        margin-left: 0;
        border-left: 2px solid var(--gray-800);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        justify-content: flex-start;
        overflow-y: auto;
    }
    
    .units-count-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: var(--font-weight-regular);
        font-size: 14px;
        display: flex;
        gap: 6px;
    
        .units-count-label {
            color: var(--gray-300);
        }
    
        .units-count-number {
            color: white;
        }
    }
    
    .success-color {
        color: var(--success-300);
    
        >svg>path {
            fill: var(--success-300);
        }
    }
    
    .warning-color {
        color: var(--error-400);
    
        >svg>path {
            fill: var(--error-400);
        }
    
        >svg {
            transform: rotateX(180deg);
        }
    }

    .doughnut-section {
        background-color: var(--gray-900);
        border-radius: 8px 0px 0px 8px;
        width: 400px;
        margin: 0px 0px 5px 5px;
        padding: 16px;
    }

    .donut-section-content {
        width: 100%;
        height: 100%;
    }

    .donut-section-body-wade-report-month {
        background: var(--gray-900);
        margin: 0px 5px 5px 5px;
        border-radius: 8px;
        padding: 44px 8px 44px 8px;
        display: flex;
        justify-content: center;
        align-items: center; 
    }
    
    .data-cards-wrapper {
        display: flex;
        flex-direction: row;
        gap: unset;
        justify-content: space-between;
        align-content: space-between;
        flex-wrap: wrap;
        row-gap: 16px;
        max-height: 112px;
        overflow-y: auto;
    }
    
    .bordered:nth-of-type(1) {
        border-left: 2px solid var(--chart-color-1);
    }
    
    .bordered:nth-of-type(2) {
        border-left: 2px solid var(--chart-color-2);
    }
    
    .bordered:nth-of-type(3) {
        border-left: 2px solid var(--chart-color-3);
    }
    
    .bordered:nth-of-type(4) {
        border-left: 2px solid var(--chart-color-4);
    }
    
    .bordered:nth-of-type(5) {
        border-left: 2px solid var(--chart-color-5);
    }
    
    .bordered:nth-of-type(6) {
        border-left: 2px solid var(--chart-color-6);
    }
    
    .bordered:nth-of-type(7) {
        border-left: 2px solid var(--chart-color-7);
    }
    
    .bordered:nth-of-type(8) {
        border-left: 2px solid var(--chart-color-8);
    }
    
    .bordered:nth-of-type(9) {
        border-left: 2px solid var(--chart-color-9);
    }
    
    .bordered:nth-of-type(10) {
        border-left: 2px solid var(--chart-color-10);
    }
    
    .data-card-large {
        display: flex;
        flex-direction: column;
        padding-left: 12px;
        row-gap: 6px;
        width: 44%;
    }
    
    .data-card-small {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 12px;
        flex-wrap: wrap;
    }
    
    .data-card-count {
        color: white;
        font-family: Criteria-CF-Demi-Bold;
        font-size: 20px;
    }
    
    .data-card-count-percentage {
        color: white;
        font-weight: var(--font-weight-semi-bold);
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 6px;
    
        >span:nth-child(3) {
            min-width: 4ch;
            text-align: end;
        }
    }
    
    .right-bar-categories {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: start;
        height: 0;
    }
    
    .activity-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-weight: 450;
    }

    .activity-headers, .activity-averages, .activity-categories {
        flex: 1 1 0px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 3px;
    }
    
    .activity-averages {
        margin-bottom: 8px;
    }
    
    .activity-header {
        display: flex;
        flex: 1 1 0px;
        justify-content: center;
    }
    
    .activity-cell {
        display: flex;
        flex: 1 1 0px;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
    }
    
    .activity-cell-data {
        color: var(--gray-900);
    }
    
    .activity-averages>div:first-child,
    .activity-headers>div:first-child,
    .activity-categories>div:first-child {
        justify-content: flex-end;
        min-width: 100px;
        flex: 1.5 1 0px;
        margin-right: 8px;
    }

    .questionMarkTooltip {
        font-size: 12px;
        font-family: Montserrat;
        background-color: var(--gray-600);
        width: 180px;
        opacity: 1;
    }

    .row {
        display: flex;
        flex-direction: row;
    }
}