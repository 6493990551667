.RecordDialog {
    padding: 16px 16px 0px 16px;
    outline: none !important;
    user-select: none !important;

    .dialog {
        display: flex;
        height: 80vh;
        width: 100%;
        background: var(--gray-900);
        border: 1px solid var(--gray-700);
        border-radius: 8px;
        font-size: 16px;
        outline: none;
        user-select: none;
    }

    *:focus {
        outline: none;
    }

    .image-header, .slider-header, .evaluation-header, .user-evaluations-title {
        color: var(--gray-400);
        font-family: Montserrat;
        font-weight: 600;
        font-size: 14px;
        margin: 0px 0px 8px 0px;
    }
    
    .user-evaluations-title {
        margin: 16px 0px 8px 0px;
    }
    
    .vertical-seperator {
        height: 100%;
        min-width: 1px;
        width: 1px;
        background-color: var(--gray-700);
    }
    
    .info-wrapper {
        width: 35%;
        padding: 18px;
        overflow: hidden;
    }
    
    .img-wrapper {
        width: 65%;
        height: calc(100% - 36px);
        padding: 18px 18px 18px 18px;
        display: flex;
        flex-direction: column;
    }
    
    .img-inner-wrapper {
        width: 100%;
        display: flex;
        flex: 1;
        gap: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 0px;
        overflow: hidden;
        margin: 0px 0px 20px 0px;
    }
    
    .cycle-btns {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .cycle-btn {
        display: grid;
        place-items: center;
        width: 32px;
        height: 32px;
        border: 1px solid var(--gray-700);
        border-radius: 8px;
        cursor: pointer;
        user-select: none;
    }
    
    .inference-img {
        width: calc(100% - 1px);
        border-radius: 8px;
        border: 1px solid var(--gray-700);
        object-fit: contain;
        background-color: #000;
        height: 100%;
    }
    
    .record-info-title {
        color: var(--gray-400);
        font-size: 14px;
        margin-bottom: 4px;
    }
    
    .record-info-value, .slider-wrapper {
        color: white;
        width: fit-content;
        font-size: 16px;
        border-radius: 8px;
        border: 1px solid var(--gray-700);
        gap: 8px;
    }
    
    .record-info-value {
        padding: 8px;
    }

    .slider-wrapper {
        margin: 0px 0px 20px 0px;
        padding: 0px 16px 0px 16px;
    }
    
    .loading-img-wrapper {
        position: relative;
        margin-bottom: 20px;
    }
    
    .loading-img-wrapper:after {
        content: "";
        position: absolute;
        display: block;
        width: 55%;
        height: 16px;
        background-color: var(--gray-900);
        left: 50%;
        top: calc(50% - 8px);
        transform-origin: center left;
        animation: rotation 2s steps(8, end) forwards infinite;
    }
    
    @keyframes rotation {
        to {
            transform: rotate(360deg);
        }
    }
    
    .category-choose-wrapper {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: 100%;
        margin-right: 16px;
        overflow: hidden;
    }
    
    .category-buttons {
        display: grid;
        grid-template-columns: auto auto;
        gap: 8px;
    }

    .row {
        display: flex;

    }

    .category-button:focus-visible, .user-added-category-button:focus-visible {
        outline: white solid 2px;
    }
    
    
    .category-button:active, .user-added-category-button:active {
        transform: scale(0.9);
    }
    
    .category-button, .user-added-category-button {
        background-color: var(--gray-800);
        border: none;
        /* Font needs to be "Text S - SemiBold" or "Text M - SemiBold" */
        font-family: Montserrat;
        font-weight: var(--font-weight-semi-bold);
        color: white;
        line-height: 18px;
        padding: 9px 16px;
        border-radius: 18px;
        text-align: center;
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
        user-select: none;
    }

    .category-button input {
        /* Make the input look like just a text inside the button */
        background-color: transparent;
        border: none;
        color: white;
        font-family: Montserrat;
        font-weight: var(--font-weight-semi-bold);
        font-size: 16px;
        line-height: 18px;
    }

    .add-category-button {
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
    }
    
    .user-added-category-button {
        display: flex;
        justify-content: center;
        gap: 16px;
    }
    
    .category-votes-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }

    .user-category-votes-container {
        display: flex;
        justify-content: center; /* Centers horizontally */
        align-items: center; 
        height: 100%;
    }
    
    .user-category-votes {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
    }
    
    .user-vote {
        width: 36px;
        height: 36px;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        /* Center horizontally */
        align-items: center;
    }

    .icon-text {
        font-family: Montserrat;
        color: var(--gray-900);
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        user-select: none;
    }

    .category-section {
        display: flex;
        justify-content: space-between;
        border: 1px solid var(--gray-700);
        border-radius: 8px;
        padding: 8px 8px 8px 8px;

        max-height: calc(100% - 36px - 1lh - 12px - 32px - 1lh - 12px - 70px);
    }
    
    .scrollable-wrapper {
        overflow: scroll;
        width: 100%;
        display: flex;
    }

    .floating-section {
        position: relative;
        top: 0px;
    }

    .setPointButton {
        position: absolute;
        bottom: 0px;
        right: 0px;
    }

    .annotationPoint {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 12px;
        height: 12px;
        background-color: red;
        border-radius: 50%;
    }

    .image-label {
        font-family: Montserrat;
        font-size: 16px;
        letter-spacing: 2px;
        display: flex;
        align-items: center;
    }

    .annotationPointAnimation {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0px;
    }

    .brightness-section {
        margin-top: 16px;
    }
}