.UnitCard {
	.img-wrapper {
	    border-radius: 8px;
	    margin: 0;
	    overflow: hidden;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    aspect-ratio: 588 / 250;
	}

	.poster-image {
	    display: block;
	    width: 100%;
	    max-height: 250px;
	    object-fit: cover;
	    position: relative;
	}

	.poster-image::before {
	    content: "";
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    background: url('../../../assets/icons/image_icon.png') no-repeat;
	    background-size: 100px 100px;
	    background-position: center;

	    filter: grayscale(100%) invert(100%);
	}

	.location-bar {
	    display: flex;
	    padding-top: 16px;
	    border-top: 2px solid var(--gray-800);
	    flex-direction: row;
	    justify-content: space-between;
	}

	.units-count-text {
	    font-family: Montserrat;
	    font-style: normal;
	    font-weight: var(--font-weight-regular);
	    font-size: 14px;
	    display: flex;
	    gap: 6px;
	}

	.units-count-label {
	    color: var(--gray-300);
	}

	.units-count-number {
	    color: white;
	}

	.location-bar-right {
	    display: flex;
	    gap: 16px;
	}

	.live-activity-wrapper {
	    display: flex;
	    align-items: center;
	}

	.live-activity-dot {
	    width: 14px;
	    height: 14px;
	    border-radius: 100%;
	    background: var(--gray-500);
	    border: 1px solid var(--gray-200);
	}
}