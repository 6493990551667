.ThirdHeader {
    .sub-sub-header {
        height: 48px;
        display: flex;
        align-items: top;
        justify-content: space-between;
        padding-left: 32px;
        padding-right: 32px;
        border-bottom: 1px solid var(--gray-700);
    }

    .toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        position: relative;
        cursor: pointer;

    }

    .toggle-active {
        /*position: absolute;
        top: 0;
        left: 0;
       z-index: 0;*/
        display: flex;
        justify-content: center;
        background: var(--gray-800);
        border-radius: 18px;
        border: 1px solid var(--gray-700);
    }

    .toggle-active, .toggle-inactive {
        padding: 0px 12px 0px 12px;
    }

    .inner-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--gray-400);
        background: transparent;
        border: 1px solid transparent;
        gap: 8px;

        /*padding: 8px 14px 8px 12px;*/
    }

    .toggle-white {
        color: white;
        transition: color 0.3s;
    }

    .tiny-icon {
        color: var(--gray-400);
    }

    .toggle-text {
        font-family: Montserrat;
        font-style: normal;
        font-size: 14px;
    }

    /* User vs machine - switch */
    .user-vs-machine-section {
        display: flex;
        align-items: center;
    }

    .user-vs-machine-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #fff;
        margin-right: 8px;
    }
    
    .user-vs-machine-toggle {
        background-color: #e5e5e5;
        border-radius: 18px;
        width: 40px;
        height: 22px;
        box-sizing: border-box;
        padding: 2px 2px 2px 2px;
        position: relative;
        top: 0px;
        cursor: pointer;
        transition: justify-content 1.3s ease, background-color 0.3s ease;
    }

    .user-vs-machine-toggle.active {
        background-color: var(--primary-green-600);
    }

    .user-vs-machine-toggle-button {
        background-color: #fff;
        border-radius: 50%;
        transition: left 0.3s ease;
        border: 1px solid #ddd;
        width: 18px;
        height: 18px;
        box-sizing: border-box;
        
        position: absolute;
        left: 2px;
    }

    .user-vs-machine-toggle-button.active {
        left: 20px;
    }

    .user-vs-machine-toggle-button:hover {
        background-color: #fff;
    }

    .user-vs-machine-toggle:active .user-vs-machine-toggle-button, .user-vs-machine-toggle-button:active {
        background-color: hsl(0, 0%, 96%);
    }
    
}