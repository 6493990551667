.ImagesSubPage {
	.outer-wrapper {
        display: flex;
        justify-content: center;
        padding: 20px
    }
    
    .main-wrapper {
        display: flex;
        flex-direction: column;
        width: 1000px;
        max-width: 1000px;
    }
    
    .records-tabs {
        display: flex;
        flex-direction: row;
        gap: 8px;
        padding-bottom: 20px;
        height: 36px;
    }

    .category-buttons {
        display: flex;
        gap: 8px;
        overflow: scroll;
    }
    
    .aris-button-secondary {
        border: 1px solid var(--gray-700);
        line-height: 16px;
        text-wrap: nowrap;
        
    }

    .records-table {
        padding: 14px 16px 16px 16px;
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: start;
        background-color: var(--gray-800);
        border-radius: 12px;
        align-items: stretch;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        font-family: Montserrat;
        font-weight: regular;
        font-size: 14px;
        padding: 5px;
        padding-top: 0;

        table-layout: auto;
    }

    th, td {
        min-width: 50px;
        padding: 0px 40px 10px 0px; /* Optional: Adds some padding to table cells */
        text-align: left; /* Optional: Aligns text to the left */
        /*width: 175px;*/
    }
    
    th {
        text-align: left;
    }
    
    .card-header {
        display: table-row;
        color: var(--gray-400);
    }
    
    .cdk-header-cell {
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 4px;
    }
    
    .cdk-header-cell:first-of-type,
    .cdk-cell:first-of-type {
        padding-left: 12px;
    }
    
    .cdk-header-cell:last-of-type,
    .cdk-cell:last-of-type {
        padding-right: 12px;
    }
    
    .cdk-column-actions {
        display: flex;
        justify-content: end;
    }
    
    .cdk-table td.cdk-cell {
        background: var(--gray-900);
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .cdk-cell {
        background: var(--gray-900);
        padding-top: 8px;
        padding-bottom: 8px;
        white-space: nowrap;
    }

    .right-cell, .first-row-right, .last-row-right {
        text-align: right;
    }

    .first-row-left {
        border-radius: 8px 0px 0px 0px;
    }

    .first-row-right {
        border-radius: 0px 8px 0px 0px;
    }

    .last-row-right {
        border-radius: 0px 0px 8px 0px;
    }

    .last-row-left {
        border-radius: 0px 0px 0px 8px;
    }
    
    tr.cdk-row {
        border-bottom: 1px solid var(--gray-800);
    }

    tr {
        border-bottom: 1px solid var(--gray-800);
    }
    
    .cdk-table tr.cdk-row:first-of-type td:first-of-type {
        border-radius: 8px 0 0 0;
    }
    
    .cdk-table tr.cdk-row:first-of-type td:last-of-type {
        border-radius: 0 8px 0 0;
    }
    
    .cdk-table tr.cdk-row:last-of-type td:first-of-type {
        border-radius: 0 0 0 8px;
    }
    
    .cdk-table tr.cdk-row:last-of-type td:last-of-type {
        border-radius: 0 0 8px 0;
    }
    
    .table-footer {
        display: flex;
        justify-content: right;
        padding-top: 5px;
        font-family: Montserrat;
        font-size: 14px;
        gap: 8px;
    }
    
    .page-paginator-total {
        display: flex;
        align-items: center;
        padding-right: 4px;
    }
    
    .tiny-icon {
        color: var(--gray-400);
    }
    
    .page-paginator-dropdown-wrapper {
        display: flex;
        place-items: center;
        place-content: center;
    
        cursor: pointer;
        position: relative;
    
    }
    
    .page-paginator-dropdown-toggle {
        width: 26px;
        height: 26px;
        align-content: center;
        text-align: center;
    
    }
    
    .page-paginator-dropdown-toggle-on {
        transform: rotate(180deg);
    }
    
    .page-paginator-dropdown-hide {
        display: none !important;
    }
    
    .page-paginator-dropdown {
        position: absolute;
        top: -144px;
        right: 0;
        display: flex;
        font-family: Montserrat;
        border-radius: 8px;
        overflow: hidden;
        background-color: var(--gray-900);
        border: 1px solid var(--gray-700);
        flex-direction: column;
        z-index: 1;
        width: max-content;
    
    }
    
    .page-paginator-dropdown-elem {
        padding: 8px;
        text-align: start;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }
    
    .page-paginator-dropdown-elem:hover {
        background-color: var(--gray-800);
        color: white;
    }
    
    .page-paginator-navigator {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: space-between;
        background: var(--gray-800);
        border-radius: 38px;
        border: 1px solid var(--gray-700);
        font-family: Montserrat;
        height: 24px;
        padding-inline: 4px;
    }
    
    .page-paginator-navigator-elem {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }
    
    .page-paginator-navigator-current {
        min-width: 5ch;
        text-align: center;
    }
    
    .page-paginator-navigator-elem-disabled {
        opacity: 0.4;
        filter: alpha(opacity=40);
        cursor: default;
    }
    
    .page-paginator-left-arrow {
        rotate: 90deg;
    }
    
    .page-paginator-right-arrow {
        rotate: -90deg;
    }
    
    .sort-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: center;
        cursor: pointer;
        background: var(--gray-800);
        border-radius: 18px;
        border: 1px solid var(--gray-700);
        padding: 6px 8px 6px 10px;
        font-family: Montserrat;
        line-height: 16px;
        font-size: 12px;
        color: var(--gray-400);
        font-weight: unset;
        gap: 6px;
        margin-left: auto;
        text-wrap: nowrap;

        cursor: pointer;
    }
    
    .sort-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
    }

    .meat-balls-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 12px;
    }

    .meat-balls-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;
    }

    .meat-balls-button {
        display: flex;
        justify-content: center;
    }
    
    .meat-balls-menu-anchor {
        position: relative;
        top: 0px;
        left: 0px;
    }

    .meat-balls-menu {
        position: absolute;
        top: 100%;
        right: 0;
        background: white;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        min-width: 200px;
        z-index: 1000;
        overflow: hidden;
    }

    .bulk-download-button {
        padding: 12px 16px;
        cursor: pointer;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        color: var(--gray-900);
        font-size: 14px;
        border-bottom: 1px solid var(--gray-100);
    }

    .bulk-download-button:last-child {
        border-bottom: none;
    }

    .bulk-download-button:hover {
        background-color: var(--gray-50);
        color: var(--primary-green-600);
    }

    .bulk-download-button:active {
        background-color: var(--gray-100);
    }

    .meat-balls-button {
        cursor: pointer;
        padding: 8px;
        border-radius: 4px;
        transition: background-color 0.2s ease;
    }

    .meat-balls-button:hover {
        background-color: var(--gray-50);
    }

    .meat-balls-button img {
        width: 20px;
        height: 20px;
    }

    .meat-balls-wrapper {
        position: relative;
    }

    .meat-balls-menu-anchor {
        position: relative;
    }
}