.ForgotPasswordDialog {
    .dialog-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .card-container {
        background: var(--gray-800);
        border-radius: 8px;
        border: 2px solid var(--gray-600);
        padding: 0px 12px 12px 12px;
        width: 320px;
    }
    
    .card-header {
        margin-bottom: 16px;
    }
    
    .card-header-title {
        color: white;
        margin: 0;
        font-size: 18px;
    }

    
    .pw-reset-error {
        color: var(--error-600);
        margin-bottom: 16px;
    }
    
    .card-actions {
        display: flex;
        justify-content: flex-end;
    }
}