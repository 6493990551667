.ActivationsOverTimeChart {
    font-family: Montserrat;
    font-size: 14px;
    color: var(--gray-300);

    .barchart-grid {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 0px 20px;
        grid-template-areas: 
            "y-axis barchart-cell"
            "filler x-axis";
    }

    .barchart-cell {
        /* See jsx file for height */

        position: relative;
        top: 0px;
    }

    .bar-chart {
        position: relative;
        height: 100%;
    }

    .y-axis {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .y-label-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .y-label-anchor {
        position: relative;
        top: 0px;
        left: 0px;
        
    }

    .y-label {
        display: flex;
        flex-direction: column;
        position: absolute;
        align-items: flex-end;
        top: 0px;
        left: 0px;
        transform: translate(0%, -25%);
    }

    .y-label-width-filler{
        position: relative;
        top: 0px;
        left: 0px;
        transform: translate(0%, -100%);
        visibility: hidden;
    }

    .y-axis-width-filler {
        visibility: hidden;
    }

    .x-axis {
        height: 20px;

        display: flex;
        padding: 0 0px;
        justify-content: space-between;
        border-top: 2px solid var(--gray-300);
        padding-top: 6px;
    }

    .x-label-container {
        width: 100%;
        margin: 0px;
        display: flex;
        justify-content: center;;
    }

    .x-label-anchor {
        position: relative;
        top: 0px;
        left: 0px
    }

    .x-label, .x-label-hidden {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0%);
    }

    .x-label {
        visibility: visible;

        color: var(--gray-500)
    }

    .x-label-hidden {
        visibility: hidden;
    }

    .y-lines {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
    }

    .y-line {
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #000;
    }

    .x-lines {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        display: flex;
        justify-content: space-between;
    }

    .x-line {
        border-left: 2px dashed var(--gray-400);
        height: 100%;
    }

    .bar-top-data {
        border-radius: 50vw 50vw 0px 0px;
    }
}