.DonutChart {
    position: relative;

    .value-label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 400;
        font-size: 16px;
        font-family: Montserrat;
        color: #000;
    }
}