.PointButton {
    display: flex;
    user-select: none;

    background-color: #fff;
    border-radius: 50vh;

    width: 155px;
    align-items: center;
}

.PointButton .point {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 4px;
    background-color: red;
}

.PointButton .circle {
    border: 1px solid red;
    border-radius: 50%;
    margin: 8px 8px 8px 8px;
}

.PointButton .text {
    font-size: 14px;
    line-height: 14px;
    color: var(--gray-800);
    font-weight: 500;
}