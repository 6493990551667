/* You can add global styles to this file, and also import other style files */

/* Brand colors */
:root {
    /* Primary - Green */
    --primary-green-100: #ECFEED;
    --primary-green-200: #DAFEDE;
    --primary-green-300: #C7FED4;
    --primary-green-400: #B9FDCF;
    /* base */
    --primary-green-500: #A2FDC9;
    --primary-green-600: #76D9AA;
    --primary-green-700: #51B690;
    --primary-green-800: #339278;
    --primary-green-900: #1F7969;

    /* Alternative - Red */
    --alternative-red-100: #FFFEF7;
    --alternative-red-200: #FFDCCF;
    --alternative-red-300: #FFC4B8;
    --alternative-red-400: #FFAAE6;
    /* base */
    --alternative-red-500: #FF8989;
    --alternative-red-600: #DB646E;
    --alternative-red-700: #B74558;
    --alternative-red-800: #932B46;
    --alternative-red-900: #7A1A3B;

    /* Alternative - Purple */
    --alternative-purple-100: #F6EAFE;
    --alternative-purple-200: #ECD5FD;
    --alternative-purple-300: #DFBBFB;
    --alternative-purple-400: #D1ADFB;
    /* base */
    --alternative-purple-500: #BD92F4;
    --alternative-purple-600: #936AD1;
    --alternative-purple-700: #6D49AF;
    --alternative-purple-800: #4B2EBD;
    --alternative-purple-900: #331C75;

    /* Alternative - Blue */
    --alternative-blue-100: #E3FEFC;
    --alternative-blue-200: #CBFCFD;
    --alternative-blue-300: #ACF4FB;
    --alternative-blue-400: #96E7F7;
    /* base */
    --alternative-blue-500: #74D5F2;
    --alternative-blue-600: #54AADO;
    --alternative-blue-700: #3A82AE;
    --alternative-blue-800: #255E8C;
    --alternative-blue-900: #163474;

    /* Success Colors */
    --success-100: #C8F9D0;
    --success-200: #94F4AC;
    --success-300: #5CE08A;
    --success-400: #33C173;
    --success-500: #039855;
    /* Base */
    --success-600: #028255;
    --success-700: #016D52;
    --success-800: #00584B;
    --success-900: #004845;

    /* Warning Colors */
    --warning-100: #FEF4D4;
    --warning-200: #FEE6A9;
    --warning-300: #FCD47E;
    --warning-400: #FAC25D;
    --warning-500: #F8A529;
    /* Base */
    --warning-600: #D5841D;
    --warning-700: #B26614;
    --warning-800: #8F4B0D;
    --warning-900: #773807;

    /* Error Colors */
    --error-100: #FEE0D3;
    --error-200: #FDB9A7;
    --error-300: #FA8A7B;
    --error-400: #F65E5A;
    --error-500: #F12531;
    /* Base */
    --error-600: #CF1B35;
    --error-700: #AD1237;
    --error-800: #8B0B35;
    --error-900: #730734;

    --chart-color-1: #74D5F2;
    --chart-color-2: #BD92F4;
    --chart-color-3: #81F3D5;
    --chart-color-4: #FF8989;
    --chart-color-5: #81ECF3;
    --chart-color-6: #53C0FF;
    --chart-color-7: #ED9BD2;
    --chart-color-8: #FF89B0;
    --chart-color-9: #90AAFF;
    --chart-color-10: #81F3AD;
}

/* Base colors */
:root {
    /* White / Gray / Black */
    --gray-0: #FFFFFF;
    --gray-25: #FCFCFC;
    --gray-50: #F7F7F7;
    --gray-100: #F5F5F5;
    --gray-200: #EDEDED;
    --gray-300: #D6D6D6;
    --gray-400: #A5A5A5;
    --gray-500: #767676;
    --gray-600: #575757;
    --gray-700: #444444;
    --gray-800: #2B2B2B;
    --gray-900: #1C1C1C;
}

:root {
    /* Font */
    --font-family: Criteria-CF-Demi-Bold, Montserrat, sans-serif;
    --font-size-s: 14px;
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;
}

:root {
    /* Spacing */
    --footer-height: 72px;
    --header-height: 92px;
}

/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
*/

@font-face {
    font-family: Montserrat;
    src: url(./assets/fonts/Montserrat/static/Montserrat-Light.ttf) format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: Montserrat;
    src: url(./assets/fonts/Montserrat/static/Montserrat-Regular.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: Montserrat;
    src: url(./assets/fonts/Montserrat/static/Montserrat-Medium.ttf) format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: Montserrat;
    src: url(./assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf) format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: Criteria-CF-Demi-Bold;
    src: url(./assets/fonts/Criteria-CF-Demi-Bold.otf) format("opentype");
}

@font-face {
    font-family: Criteria-CF-Regular;
    src: url(./assets/fonts/Criteria-CF-Regular.otf) format("opentype");
}



html {
    font-family: Criteria-CF-Demi-Bold, Montserrat, sans-serif;
}

body {
    margin: 0;
    padding: 0;
    background-color: var(--gray-900);
    color: white;
}

html,
body, 
#root {
    height: 100%;
}


.aris-button-primary {
    background-color: var(--primary-green-500);
    border: none;
    /* Font needs to be "Text S - SemiBold" or "Text M - SemiBold" */
    font-family: Montserrat;
    font-weight: var(--font-weight-semi-bold);
    color: var(--gray-900);
    padding: 9px 16px;
    height: 36px;
    border-radius: 18px;
    line-height: 18px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
}

.aris-button-primary-disabled {
    background-color: transparent;
    color: var(--gray-500);
    cursor: default;
    user-select: none;
}

.aris-button-primary:focus-visible,
.aris-button-secondary:focus-visible {
    outline: white solid 2px;
}

.aris-button-primary:active,
.aris-button-secondary:active {
    transform: scale(0.9);
}

.aris-button-secondary {
    background-color: var(--gray-800);
    border: none;
    /* Font needs to be "Text S - SemiBold" or "Text M - SemiBold" */
    font-family: Montserrat;
    font-weight: var(--font-weight-semi-bold);
    color: white;
    height: 36px;
    line-height: 18px;
    padding: 9px 16px;
    border-radius: 18px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
}

.card-container {
    width: 440px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: start;
    background-color: var(--gray-800);
    border-radius: 12px;
    align-items: stretch;
}

.card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 20px 16px 20px;
    align-self: stretch;
}

.card-header-title {
    justify-content: flex-start;
    font-family: Montserrat;
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.card-header-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.card-body {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--gray-900);
    margin: 5px;
    margin-top: 0;
}

.card-actions {
    display: flex;
    padding: 0px 4px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.aris-input {
    height: 50px;
    border-radius: 6px;
    border: none;
    background-color: var(--gray-800);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    padding-left: 14px;
    color: white;
}

.aris-input:focus-visible {
    outline: white solid 2px;
}

.aris-input::placeholder {
    color: var(--gray-500);
}

.aris-tooltip * {
    font-family: Montserrat !important;
    background-color: var(--gray-600) !important;
    /* font-size: 14px !important; */
}

.question-icon {
    position: absolute;
    color: var(--gray-400);
    width: 16px;
}
