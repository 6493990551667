.LoginPage .login_card {
    display: flex;
    gap: 16px;
    flex-direction: column;
    position: relative;
}

.LoginPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 90px;

    .main-logo {
        width: 260px;
    }

    .login-error {
        color: var(--error-600);
    }

    .btn-wrapper {
        position: relative;
    }

    .options-btn {
        cursor: pointer;
        height: 100%;
    }

    .options-dropdown {
        position: absolute;
        top: 28px;
        display: flex;
        font-family: Montserrat;
        border-radius: 8px;
        overflow: hidden;
        background-color: var(--gray-900);
        border: 1px solid var(--gray-700);
        flex-direction: column;
        z-index: 1;
        width: max-content;
        padding: 8px;
        cursor: pointer;
    }

    .options-dropdown-item {
        user-select: none;
    }

    .card-header-actions {
        gap: 12px;
    }

    .email-sent-icon-wrapper {
        display: flex;
        align-items: flex-end;
    }

    .forgot-password {
        color: var(--gray-400);
        cursor: pointer;
        font-family: Montserrat;
        position: absolute;
        left: 0;
        bottom: -20px;
        font-size: 12px;
        line-height: 12px;
    }
}