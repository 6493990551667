.DatePicker {
    /* Make text not selectable */
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .toggle-row {
        display: flex;
        align-items: center;
    }

    .prev-button, .next-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50vh;
        background-color: var(--gray-800);
        /*border: 1px solid var(--gray-700);*/
        padding: 4px;
        cursor: pointer;

        > img {
            width: 8px;
            height: 8px;
        }
    }

    .toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--gray-700);
        border-radius: 50vh;
        background-color: var(--gray-800);
        color: var(--gray-400); 
        cursor: pointer;
        font-size: 12px;
        font-family: Montserrat;
        font-weight: 400;
        padding: 6px 8px 6px 11px;
        margin: 0px 6px 0px 6px;
    }

    .expand-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50vh;
        background-color: var(--gray-700);
        padding: 4px;

        margin: 0px 0px 0px 6px;

        > img {
            width: 8px;
            height: 8px;
        }
    }

    .content-wrapper {
        position: relative;
        right: 0px;
        top: 4px;
    }

    .content {
        position: absolute;

        /* TODO: make component unaware of necessary z-index. Maybe pass it as a prop */
        z-index: 2;

        display: flex;
        flex-direction: column;
        background-color: var(--gray-900);
        border: 1px solid var(--gray-700);
        border-radius: 8px;

        font-family: Montserrat;
        font-weight: 500;
        font-size: 15px;
    }

    .default-anchored .content {
        right: 0px;
    }

    .right-anchored .content {
        left: 0px;
    }

    .year-row {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 0px;

        border-bottom: 1px solid var(--gray-700);

        .left-arrow, .right-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 8px;
            cursor: pointer;
        }
    }

    .year, .selected-year {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--gray-700);
        border-radius: 50vh;
        padding: 4px 12px;
        cursor: pointer;
    }

    .selected-year {
        background-color: var(--gray-500);
    }

    .colomns {
        display: flex;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 500;
        padding: 16px 12px 20px 12px;
    }

    .month-tower {
        margin-right: 8px;
    }
    
    .switch-button, .selected-switch-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 10px;
        border: 1px solid var(--gray-700);
        border-radius: 50vh;
        margin: 0px 0px 16px 0px;
    }

    .week-tower .switch-button, .month-tower .switch-button {
        cursor: pointer;
    }

    .week-only-week-tower .switch-button {
        cursor: default;
    }

    .selected-switch-button {
        background-color: var(--gray-700);
    }

    .month-tower .switch-button, .month-tower .selected-switch-button {
        margin-right: 12px;
    }
    
    .month-colomn {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0px;
        padding: 0px 24px 0px 8px;
        border-right: 1px solid var(--gray-700);
    }

    .selected-month-button, .month-button, .inactive-month-button {
        cursor: pointer;
        width: 52px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
    }

    .inactive-month-button {
        font-weight: 400;
        color: var(--gray-500);
    }

    .selected-month-button {
        background-color: var(--gray-700);
        border-radius: 50vh;
        border: 1px solid var(--gray-500);
        box-sizing: border-box;
    }

    .week-tower {
        padding: 0px 0px 0px 8px;
        margin-right: 3px;
    }

    /* The week tower, when the type of the DatePicker is *week* */
    .week-only-week-tower {
        padding: 0px 0px 0px 0px;
    }

    .week-tower, .week-only-week-tower {
        display: flex;
        flex-direction: column;
    }

    .week-tower .switch-button, .week-tower .selected-switch-button {
        margin-right: 2px;
    }

    .week-only-week-tower .switch-button, .week-only-week-tower .selected-switch-button {
        margin-right: 0px;
    }

    .week-colomn {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
    }
    
    .week-tower .week-colomn {
        padding: 0px 0px 0px 6px;
    }

    .week-only-week-tower .week-colomn {
        padding: 0px 0px 0px 0px;
    }

    .week-row {
        display: flex;
        width: 100%;
    }

    .inactive-week-button, .selected-week-button, .week-button, .filler {
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
    }

    .inactive-week-button {
        font-weight: 400;
        color: var(--gray-500);
    }

    .selected-week-button, .week-button {
        cursor: pointer;
    }

    .selected-week-button {
        background-color: var(--gray-700);
        border-radius: 50vh;
        border: 1px solid var(--gray-500);
        box-sizing: border-box;
    }

    .actions-row {
        display: flex;
        justify-content: space-between;
        padding: 8px 16px;
        border-top: 1px solid var(--gray-700);
    }

    .right-section {
        display: flex;
        gap: 12px;
    }

    .save-button, .ytd-button, .cancel-button {
        padding: 5px 14px 7px 14px;
        cursor: pointer;
    }

    .save-button, .ytd-button {
        background-color: var(--primary-green-500);
        color: #030303;
        border-radius: 50vh;
    }

    .cancel-button {
        color: white;
    }
}