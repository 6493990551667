.AppHeader .main-wrapper {
    display: flex;
    height: var(--header-height);
    min-height: var(--header-height);
    align-items: center;
    justify-content: space-between;
    padding-inline: 32px;
}

.AppHeader .logo {
    height: 28px;
    flex: 1;
}

.AppHeader .wadeReportModeLogo img {
    height: 40px;
}

.AppHeader .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    position: relative;
    cursor: pointer;
}

.AppHeader .inner-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    color: var(--gray-400);
    background: transparent;
    border: 1px solid transparent;
    gap: 8px;

    padding: 8px 14px 8px 12px;
}
.AppHeader .tiny-icon {
    color: var(--gray-400);
}

.AppHeader .icon-text {
    font-family: Montserrat;
    color: var(--gray-900);
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    user-select: none;
}

.AppHeader .toggle-text {
    font-family: Montserrat;
    font-style: normal;
    font-size: 14px;
}

.AppHeader .toggle-white {
    color: white;
    transition: color 0.3s;
}

.AppHeader .user-button {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: var(--alternative-red-500);
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    flex: 1;
}

.AppHeader .user-button-wrapper {
    position: relative;
}

.AppHeader .flex-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.AppHeader .header-dropdown {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    white-space: nowrap;
}

.AppHeader .header-dropdown-hide {
    display: none !important;
}